import { http } from 'services/http'
import { getRequestUrl } from 'utils/requestUtils'
import { QueryKeys } from 'constants/queries'
import { GetExpertsResponse } from 'models/experts/ApiModels'

type QueryParams = {
  id: number
}

export const getFestivalExperts = (data): Promise<GetExpertsResponse> =>
  http.get<GetExpertsResponse, GetExpertsResponse>(
    getRequestUrl(data?.queryKey && data?.queryKey[1], 'festival-experts')
  )

export const getFestivalExpertById = ({ queryKey: [, { id }] }: { queryKey: [QueryKeys, QueryParams] }): Promise<any> =>
  http.get(`/festival-experts/${id}`)

export const getIndustryRepresentatives = (data): Promise<GetExpertsResponse> =>
  http.get<GetExpertsResponse, GetExpertsResponse>(
    getRequestUrl(data?.queryKey && data?.queryKey[1], 'industry-representatives')
  )

export const getIndustryRepresentativeById = ({
  queryKey: [, { id }]
}: {
  queryKey: [QueryKeys, QueryParams]
}): Promise<any> => http.get(`/industry-representatives/${id}`)
