import styled, { css } from 'styled-components'
import Colors from 'constants/colors'
import Threshold from 'constants/threshold'

export const CoverStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 60px;

  .margin-top--m {
    margin-top: 20px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 115px;
    z-index: 10;
    color: ${Colors.white};

    h1 {
      margin-bottom: 15px;
    }
    .description {
      display: none;
    }
    span {
      cursor: pointer;
      color: ${Colors.turquoise};
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    p {
      margin-bottom: 10px;
    }
    .instruction {
      margin-top: 14px;
    }
  }

  .wrapper__faq-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 115px;
    z-index: 10;
    color: ${Colors.white};

    h1 {
      margin-bottom: 15px;
    }
    span {
      color: ${Colors.red};
    }
  }

  .mobile {
  }
  .desktop {
    display: none;
  }

  @media (min-width: ${Threshold.phone}) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }

    .wrapper {
      align-items: flex-start;
      top: 28%;
      left: 13%;
      h1 {
        margin-bottom: 25px;
        text-align: start;
        font-weight: bold;
        font-size: 35px;
        line-height: 50px;
      }
      .description {
        display: block;
        font-size: 20px;
        line-height: 35px;
      }
      p {
        font-size: 20px;
        line-height: 35px;
        margin-bottom: 30px;
      }
      span {
        font-size: 20px;
        line-height: 30px;
      }
      .instruction {
        margin-top: 20px;
      }
    }

    .wrapper__faq-page {
      align-items: flex-start;
      top: 28%;
      left: 13%;

      h1 {
        margin-bottom: 25px;
        text-align: start;
        font-weight: bold;
        font-size: 35px;
        line-height: 50px;
      }
    }
  }
`

export const Wrapper = styled.div<{ isMainPage: boolean }>`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  width: 249px;
  position: absolute;
  top: 30px;
  left: 23px;
  z-index: 10;
  color: ${Colors.white};

  @media (min-width: ${Threshold.phone}) {
    align-items: flex-start;
    width: auto;
    top: 23%;
    left: 13%;
  }

  ${props =>
    props.isMainPage &&
    css`
      @media (min-width: ${Threshold.phone}) {
        top: 10%;
        left: 6%;
      }
      @media (min-width: ${Threshold.tablet}) {
        align-items: flex-start;
        left: 13%;
      }
      @media (min-width: ${Threshold.desktop}) {
        align-items: flex-start;
        top: 12%;
      }
      @media (min-width: ${Threshold.desktopLarge}) {
        align-items: flex-start;
        top: 24%;
      }
    `}
`
export const HeadlinerText = styled.p`
  font-weight: bold;
  max-width: 90%;
  font-size: 12px;
  line-height: 15px;
  text-transform: none;
  margin-bottom: 8px;

  @media (min-width: ${Threshold.phone}) {
    font-size: 20px;
    line-height: 35px;
    margin-bottom: 0;
  }
`

export const HeadlinerName = styled.p`
  text-transform: none;
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: ${Colors.yellow};

  @media (min-width: ${Threshold.phone}) {
    margin-bottom: 32px;
    text-align: start;
    font-weight: bold;
    font-size: 35px;
    line-height: 50px;
  }
`

export const Title = styled.h4`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;

  @media (min-width: ${Threshold.phone}) {
    margin-bottom: 25px;
    text-align: start;
    font-weight: bold;
    font-size: 35px;
    line-height: 50px;
  }
`

export const MainTitle = styled.h4`
  max-width: 90%;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-transform: none;

  @media (min-width: ${Threshold.phone}) {
    margin-bottom: 16px;
    text-align: start;
    font-weight: bold;
    font-size: 35px;
    line-height: 50px;
  }
`

export const LecturesDescription = styled.p`
  display: none;
  text-transform: none;

  @media (min-width: ${Threshold.phone}) {
    display: block;
    font-size: 20px;
    line-height: 35px;
  }
`

export const MainDescription = styled.p`
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 16px;
  text-transform: none;

  @media (min-width: ${Threshold.phone}) {
    font-size: 20px;
    line-height: 35px;
    margin-bottom: 30px;
  }
`

export const Slash = styled.span`
  color: ${Colors.red};
`
