import React from 'react'

import { BackgroundImageStyled } from './styles'

export interface BackgroundImageProps {
  width?: string
  height?: string
  src: string
  srcMobile: string
  position: 'center' | 'bottom'
  srcMobileSize?: {
    width: string
    height: string
  }
}
/**
 * Props
 * @param {string} width? {string} - в vw;
 * @param {string} height? {string} - в vw;
 * @param {string} src {string} - путь к изображению;
 * @param {string} srcMobile {string} - путь к изображению моб;
 * @param {string} position {string} - "center" | "bottom".
 */

const BackgroundImage: React.FC<BackgroundImageProps> = ({
  width,
  height,
  src,
  srcMobile,
  srcMobileSize,
  position
}) => {
  if (position === 'center') {
    return (
      <BackgroundImageStyled>
        <svg
          className="desktop"
          width={width || '100vw'}
          height={height || '43.4027vw'}
          viewBox="0 0 1440 625"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1161.71 596H1147L1203.29 473H1218L1161.71 596Z" fill="#E63C24" />
          <path d="M1440 625L629.5 411.5L0 105L1440 26.5V625Z" fill="#67DBCA" />
          <mask id="mask-center" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1440" height="521">
            <path d="M1373.5 521L58.5 359L0 133.5V76.5L1440 0V55L1373.5 521Z" fill="#67DBCA" />
          </mask>
          <g mask="url(#mask-center)">
            <image width="1440" height="530" href={src} />
          </g>
          <path d="M1209.71 582H1195L1251.29 459H1266L1209.71 582Z" fill="#E63C24" />
        </svg>

        <svg
          className="mobile"
          width="100vw"
          height="66.6vw"
          viewBox="0 0 375 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M289.215 248H283L306.785 193H313L289.215 248Z" fill="#E63C24" />
          <path d="M375 250L147.045 182.486L0 80L375 13V250Z" fill="#67DBCA" />
          <mask
            id="mask-center-mobile"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="375"
            height="220"
          >
            <path d="M375 220L0 170V57V20L375 0L375 139.5L375 220Z" fill="#67DBCA" />
          </mask>
          <g mask="url(#mask-center-mobile)">
            <image width={srcMobileSize?.width || '375'} height={srcMobileSize?.height || '220'} href={srcMobile} />
          </g>
          <path d="M309.629 241H303L328.371 186H335L309.629 241Z" fill="#E63C24" />
        </svg>
      </BackgroundImageStyled>
    )
  } else {
    return (
      <BackgroundImageStyled>
        <svg
          className="desktop"
          width={width || '100vw'}
          height={height || '43vw'}
          viewBox="0 0 1440 612"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1440 330.5V611.5H0V126.5L1128 0L1440 330.5Z" fill="#67DBCA" />
          <mask id="mask-bottom" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="22" width="1440" height="590">
            <path d="M1440 221.5V611.5H0V161.5L1250 22.5L1440 221.5Z" fill="black" />
          </mask>
          <g mask="url(#mask-bottom)">
            <image width="1440" height="590" href={src} y="22" />
          </g>
        </svg>

        <svg
          className="mobile"
          width="100vw"
          height="70vw"
          viewBox="0 0 375 262"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M375 37.5V261.5H0V41L341.5 0L375 37.5Z" fill="#67DBCA" />
          <mask
            id="mask-bottom-mobile"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="11"
            width="375"
            height="251"
          >
            <path d="M375 88.5L375 261.5H0V60.5L375 11.5L375 88.5Z" fill="#67DBCA" />
          </mask>
          <g mask="url(#mask-bottom-mobile)">
            <image width="375" height="251" href={srcMobile} y="11" />
          </g>
        </svg>
      </BackgroundImageStyled>
    )
  }
}

export default BackgroundImage
