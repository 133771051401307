import Link from 'next/link'
import { Fragment } from 'react'
import { separatorForHyperLink } from './data'

import * as SC from './styles'

export interface DescriptionHyperLink {
  href: string
  text: string
}

export const parseDescription = (description: string, descriptionHyperLinks?: DescriptionHyperLink[]) => {
  if (descriptionHyperLinks) {
    const dividedDescription = description.split(separatorForHyperLink)

    return dividedDescription.map((descriptionItem, index) => (
      <Fragment key={descriptionItem} >
        {descriptionItem}
        {descriptionHyperLinks[index] && (
          <Link href={descriptionHyperLinks[index].href} passHref>
            <SC.HyperLink  target="_blank">
              {descriptionHyperLinks[index].text}
            </SC.HyperLink>
          </Link>
        )}
      </Fragment>
    ))
  } else {
    return <>{description}</>
  }
}