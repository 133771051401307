import { QueryParams } from 'models/common/api'
import qs from 'qs'

export const getRequestUrl = (requestQueryParams: QueryParams, pass: string) => {
  if (!requestQueryParams) return `/${pass}`

  const queryParams = qs.stringify(
    {
      search: requestQueryParams.search,
      offset: requestQueryParams.offset,
      limit: requestQueryParams.limit,
      finalists: requestQueryParams.finalists
    },
    { addQueryPrefix: true }
  )
  return `/${pass}${queryParams}`
}
