import React from 'react'
import Button from 'components/common/Button'
import * as SC from './styles'
import { festivalDates } from 'constants/festivalDates'
import useWindowSize from 'hooks/useWindowSize'
import Threshold from 'constants/threshold'

export interface CoverProps {
  src: string
  srcMobile: string
  srcSize?: {
    width: string
    height: string
  }
  srcMobileSize?: {
    width: string
    height: string
  }
  pageData?: PageDataProps
}

type PageDataProps = {
  type: 'commonPage' | 'mainPage' | 'lecturesPage' | 'faqPage'
  title?: string
  hasNewOpenText?: boolean
}

/**
 * Props
 * @param {string} src {string} - путь к изображению;
 * @param {string} srcMobile {string} - путь к мобильному изображению;
 * @param {JSX.Element} children {JSX.Element} - children;
 * @param {PageDataProps} pageData {PageDataProps} - Тип страницы;
 */

const Cover: React.FC<CoverProps> = ({ src, srcMobile, srcSize, srcMobileSize, pageData }) => {

  const windowSize = useWindowSize()
  //
  return (
    <SC.CoverStyled>
      <svg
        className="desktop"
        width="100vw"
        height="54.166vw"
        viewBox="0 0 1440 780"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M161.094 743H141L217.906 574H238L161.094 743Z" fill="#E63C24" />
        <path d="M47 0H1440V780L78.8672 611.394L60.9336 255.697L47 0Z" fill="#67DBCA" />
        <mask id="mask-cover" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1440" height="704">
          <path d="M0 0H1440V479L1315 704L0 553V0Z" fill="black" />
        </mask>
        <g mask="url(#mask-cover)">
          <image width={srcSize?.width || '1440'} height={srcSize?.height || '704'} href={src} />
        </g>
        <path d="M113.094 695H93L169.906 526H190L113.094 695Z" fill="#E63C24" />
        <image x="1000" y="620" width={'289'} height={'108'} href={'images/homePage/coverDate.png'} />
      </svg>

      <svg
        className="mobile"
        width="100vw"
        height="92vw"
        viewBox="0 0 375 345"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M41.9517 334.708H36.7188L56.7462 290.698H61.9792L41.9517 334.708Z" fill="#E63C24" />
        <path d="M12.5504 142H375V344.344L20.5384 300.436L12.5504 142Z" fill="#67DBCA" />
        <mask id="mask-cover-mobile" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="375" height="325">
          <path d="M0 0H375V265.958L342.448 324.552L0 285.229V0Z" fill="#67DBCA" />
        </mask>
        <g mask="url(#mask-cover-mobile)">
          <image
            x={'-300'} // remove to center image
            width={srcMobileSize?.width || '375'}
            height={srcMobileSize?.height || '325'}
            href={srcMobile}
          />
        </g>
        <path d="M29.4517 322.208H24.2188L44.2462 278.198H49.4792L29.4517 322.208Z" fill="#E63C24" />
        <image x="262" y="290" width={'96'} height={'36'} href={'images/homePage/coverDate.png'} />
      </svg>

      <SC.Wrapper
        isMainPage={false}
        // isMainPage={pageData?.type === 'mainPage'}
      >
        {pageData?.type === 'faqPage' && (
          <SC.Title>
            Вопрос <SC.Slash>/</SC.Slash> Ответ <br /> про фестиваль
          </SC.Title>
        )}

        {pageData?.type === 'commonPage' &&
          (pageData?.hasNewOpenText ? (
            <SC.Title>
              {pageData?.title}
              <br />
              New <SC.Slash>/</SC.Slash> Open 2024
            </SC.Title>
          ) : (
            <SC.Title>{pageData?.title}</SC.Title>
          ))}

        {pageData?.type === 'lecturesPage' && (
          <>
            <SC.Title>ЛЕКЦИИ И МАСТЕР-КЛАССЫ</SC.Title>
            <SC.LecturesDescription>
              Букеры, пиарщики и директора клубов о том, как работает
              <br />
              музыкальная индустрия.
            </SC.LecturesDescription>
          </>
        )}

        {pageData.type === 'mainPage' && (
          <>
            {windowSize.width < parseInt(Threshold.phone,10) && <SC.MainDescription>Екатеринбург 9-11 февраля 2024</SC.MainDescription>}
            <SC.MainTitle>Шоукейс-фестиваль актуальной музыки</SC.MainTitle>
            {/* <SC.MainDescription>{festivalDates} февраля 2023</SC.MainDescription> */}
            {windowSize.width >= parseInt(Threshold.phone,10) && <SC.MainDescription>Екатеринбург 9-11 февраля 2024</SC.MainDescription>}
            <SC.HeadlinerText>Хедлайнер фестиваля -</SC.HeadlinerText>
            <SC.HeadlinerName>Максим Свобода</SC.HeadlinerName>

            <Button big href="https://pclad.timepad.ru/event/2754107/" target="_blank" rel="noopener noreferrer">
              Зарегистрироваться
            </Button>
            {/* <Button
              big
              href="https://band.link/newopen2024?fbclid=PAAabKQBl7lKu3MryDi09TSFVp_Q0kdNE1Fexwa2JRnhyNY6en83sO54nZ8F0"
              target="_blank"
              rel="noopener noreferrer"
              className="margin-top--m"
            >
              Регистрация на фестиваль
            </Button> */}
            {/* <Button
              big
              href={process.env.NEXT_PUBLIC_ADDRESS_CRM}
              // isAnimated
              // href="https://www.youtube.com/watch?v=WKnRutS3RgI&list=PLQGC9hdc4qr9ZV5DIXstXvyHZplhLupPx"
              target="_blank"
              rel="noopener noreferrer"
              className="margin-top--m"
            >
              Подать заявку 
            </Button> */}
            {/* <p className="instruction">
            Как подать заявку?{" "}
            <Link href="/lectures/3" shallow>
              <span>Инструкция</span>
            </Link>
          </p> */}
          </>
        )}
      </SC.Wrapper>
    </SC.CoverStyled>
  )
}

export default Cover
