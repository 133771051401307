import styled from "styled-components";
import Colors from "constants/colors";
import Threshold from "constants/threshold";

export const SliderWithTextStyled = styled.div`
  position: relative;

  h2 {
    margin-bottom: 34px;
    padding: 0 22px 0 23px;
    text-align: center;
    text-transform: uppercase;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    .button {
      width: 14px;
      height: 24px;
      padding: 0 20px;
      cursor: pointer;
      &-prev {
        background: url("/svgs/arrow-prev.svg") no-repeat center top;
        background-size: contain;
      }
      &-next {
        background: url("/svgs/arrow-next.svg") no-repeat center top;
        background-size: contain;
      }
      &:hover {
        opacity: 50%;
      }
    }
  }

  @media (min-width: ${Threshold.phone}) {
    width: 100%;
    h2 {
      margin-bottom: 0;
      padding: 0 22px 0 23px;
    }

    .buttons {
      justify-content: flex-start;
    }
  }
`;

export const SlideStyled = styled.div`
  h3 {
    margin-bottom: 25px;
    text-transform: uppercase;
  }

  .text-container {
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 0 22px 0 23px;

    & p {
      min-height: 108px;
    }
  }
  .image-container {
    margin-bottom: 64px;

    & .desktop {
      display: none;
    }
  }

  @media (max-width: ${Threshold.phone}) {
    .text-container {      
      & p {
        
        margin-bottom: 20px;
      }
    }
  }

  @media (min-width: ${Threshold.phone}) {
    display: flex;
    align-items: center;

    .text-container {
      max-width: 500px;
      padding-left: 67px;
      margin-bottom: 50px;

      & p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 50px;
        min-height: 144px;
      }
      & h3 {
        font-size: 20px;
        line-height: 25px;
        text-align: start;
      }
    }
    .image-container {
      margin-bottom: 0;
      order: -1;

      & .mobile {
        display: none;
      }
      & .desktop {
        display: block;
      }
    }
  }
`;


export const HyperLink = styled.a`
  color: ${Colors.red};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

export const InlineText = styled.span`
  display: inline;
`