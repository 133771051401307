export const separatorForHyperLink = '|||||'

const slides = [
  {
    title: "50 УЧАСТНИКОВ",
    description:
      "Совет представителей индустрии рассмотрит заявки и выберет не менее 50 исполнителей, которые смогут претендовать на гранты и другие призы фестиваля.",
    image: "/images/homePage/slide1.jpg",
  },
  {
    title: "Обучение",
    description:
      `Все желающие могут прокачать свои скиллы на образовательном курсе фестиваля. Лекции и дискуссии о творческом проектировании, фандрайзинге, маркетинге, дистрибуции и PR. Ищите актуальный полезный контент в разделе "Лекторий".`,
    image: "/images/homePage/slide2.jpg",
    descriptionHyperLinks: []
    // descriptionHyperLinks: [
    //   {
    //     href: 'https://mastera.academy/courses/kak-zarabatyvat-na-muzyke/',
    //     text: 'Mastera.academy'
    //   }
    // ]
  },
  {
    title: "Грантовый фонд",
    description:
      "Грантовый фонд фестиваля в этом году составит не менее 1 миллиона рублей. Экспертный совет выберет не менее 3 победителей, которые получат деньги, а также финалистов, которые получат не денежные призы.",
    image: "/images/homePage/slide3.jpg",
  },
  {
    title: "Шоукейс-фестиваль",
    description:
      "В финале проекта коллективы защищают проекты и выступают перед аудиторией фестиваля. Место встречи музыкантов, промоутеров, организаторов фестивалей и других участников музыкальной индустрии.",
    image: "/images/homePage/slide4-1.png",
  },
];


export default slides;
