export enum QueryKeys {
  FESTIVAL_EXPERT = 'festival-expert',
  FESTIVAL_EXPERTS = 'festival-experts',
  ARTISTS = 'artists',
  PARTNERS = 'partners',
  PROGRAM = 'program',
  COURSES = 'courses',
  COURSE = 'course',
  ARTIST = 'artist',
  HEADLINERS = 'headliners',
  INDUSTRY_REPRESENTATIVES = 'industry-representatives',
  INDUSTRY_REPRESENTATIVE = 'industry-representative'
}
