import {
  GetExpertByIdResponse,
  GetExpertsResponse
} from 'models/experts/ApiModels'
import {
  ExpertByIdInfo,
  Experts
} from 'models/experts/EntityModels'

export const deserializeExperts = (data: GetExpertsResponse): Experts => {
  return {
    count: data.count || 0,
    items: data.items.map(item => ({
      id: item.id,
      name: item.name,
      description: item.description,
      shortDescription: item.short_description,
      photo: item.photo,
      slug: item.slug
    }))
  }
}

export const deserializeExpert = (data: GetExpertByIdResponse): ExpertByIdInfo => {
  return {
    id: data.id,
    description: data.description,
    shortDescription: data.short_description,
    city: data.city,
    facebookUrl: data.facebook_url,
    instagramUrl: data.instagram_url,
    name: data.name,
    photo: data.photo,
    projectLink: data.project_link,
    tiktokUrl: data.tiktok_url,
    twitterUrl: data.twitter_url,
    vkontakteUrl: data.vkontakte_url,
    youtubeUrl: data.youtube_url
  }
}
