import styled, { css } from "styled-components";
import Colors from "constants/colors";
import Threshold from "constants/threshold";

export const BackgroundImageStyled = styled.div`
  .desktop {
    display: none;
  }

  @media (min-width: ${Threshold.phone}) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
`;
