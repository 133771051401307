import { useQuery } from 'react-query'

import { QueryKeys } from 'constants/queries'
import { GetExpertsResponse } from 'models/experts/ApiModels'
import { Experts } from 'models/experts/EntityModels'
import { deserializeExperts } from 'serializers/experts'
import { getFestivalExperts } from 'api/experts'
import { QueryParams } from 'models/common/api'

const useGetFestivalExperts = (params: QueryParams) =>
  useQuery([QueryKeys.FESTIVAL_EXPERTS, params], getFestivalExperts, {
    select: (data: GetExpertsResponse): Experts => deserializeExperts(data)
  })

export default useGetFestivalExperts
