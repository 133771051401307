import styled from 'styled-components'
import Threshold from 'constants/threshold'
import Colors from 'constants/colors'

export const IndexPageStyled = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  & .active {
    color: ${Colors.turquoise};
  }

  & .pagination {
    display: flex;
    justify-content: center;

    & li:not(:last-child) {
      margin-right: 10px;
    }

    & li {
      width: 32px;
      height: 32px;
      cursor: pointer;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 2px;

      & a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        user-select: none;
      }

      & a:focus {
        border: none;
        outline: none;
      }
    }
  }
`

export const LogoLiteStyled = styled.div`
  background: url('/svgs/logo-lite.svg') no-repeat left top;
  background-size: contain;
  width: 150px;
  height: 25px;
  margin-bottom: 40px;

  @media (min-width: ${Threshold.phone}) {
    width: 250px;
    height: 40px;
    margin-bottom: 30px;
  }
`

export const VkMusicLogoStyled = styled.div`
  background: url('/svgs/vkMusic.svg') no-repeat left top;
  background-size: contain;
  width: 130px;
  height: 25px;
  margin-right: 8px;

  @media (min-width: ${Threshold.phone}) {
    width: 200px;
    height: 40px;
    margin-right: 12px;
  }
`

export const TramplinLogoStyled = styled.div`
  background: url('/svgs/tramplinLogo.svg') no-repeat left top;
  background-size: contain;
  width: 130px;
  height: 25px;

  @media (min-width: ${Threshold.phone}) {
    width: 190px;
    height: 35px;
  }
`

export const EntryDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  width: 100%;
`

export const EntryDescriptionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 880px;
  padding: 0 23px 40px;

  @media (min-width: ${Threshold.phone}) {
    padding: 0 23px 40px;
  }
`

export const IntervalWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  margin-top: 40px;

  @media (min-width: ${Threshold.phone}) {
    margin-bottom: 60px;
  }
`

export const IntervalLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const IntervalRight = styled.div``

export const IntervalVector = styled.div`
  background: url('/svgs/vector.svg') no-repeat left center;
  background-size: contain;
  width: 23px;
  height: 40px;
  margin: 0 14px;

  @media (min-width: ${Threshold.phone}) {
    height: 50px;
    margin: 0 41px 0 51px;
  }
`

export const IntervalDate = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;

  @media (min-width: ${Threshold.phone}) {
    font-size: 30px;
    line-height: 50px;
    margin-bottom: 15px;
  }
`

export const IntervalName = styled.p`
  line-height: 22px;

  @media (min-width: ${Threshold.phone}) {
    line-height: 35px;
  }
`

export const Description = styled.p`
  font-size: 14px;
  text-align: center;

  @media (min-width: ${Threshold.phone}) {
    font-size: 20px;
    line-height: 35px;
    text-align: start;
  }
`

export const PartnerSupport = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
  @media (min-width: ${Threshold.phone}) {
    margin-bottom: 0;
    margin-right: 64px;
    font-size: 20px;
    line-height: 35px;
  }
`

export const Partner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.gray};
  width: 100%;
  padding-block: 32px;
  padding-inline: 23px;

  @media (min-width: ${Threshold.phone}) {
    flex-direction: row;
  }
`

export const PartnerBlock = styled.div`
  display: flex;
  align-items: center;
`

export const MobileButtonWrapper = styled.div`
  max-width: 70%;
`
