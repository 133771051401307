import axios from 'axios'

const interceptors = {
  onRequestSuccess: response => response.data,
  onRequestError: error => Promise.reject(error)
}

export const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ADDRESS,
  headers: {
    Accept: 'application/json',
    'Accept-Language': 'ru',
    Secret: process.env.NEXT_PUBLIC_API_SECRET
  }
})

http.interceptors.response.use(interceptors.onRequestSuccess, interceptors.onRequestError)
