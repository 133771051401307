import React, { useEffect, useState } from 'react'

import { SliderWithTextStyled, SlideStyled } from './styles'

import slides from './data'
import { parseDescription } from './utils'

const SliderWithText = () => {
  const [indexSlide, setIndexSlide] = useState(0)

  const onNext = () => {
    if (indexSlide + 1 > slides.length - 1) {
      setIndexSlide(0)
    } else setIndexSlide(indexSlide + 1)
  }

  const onPrev = () => {
    if (indexSlide - 1 < 0) {
      setIndexSlide(slides.length - 1)
    } else setIndexSlide(indexSlide - 1)
  }

  const parsedDescription = parseDescription(slides[indexSlide].description, slides[indexSlide].descriptionHyperLinks)



  return (
    <SliderWithTextStyled>
      <h2>Что ждет участников</h2>

      <SlideStyled>
        <div className="text-container">
          <h3>{slides[indexSlide].title}</h3>
          <p>{parsedDescription}</p>
          <div className="buttons">
            <div onClick={onPrev} className="button button-prev" />
            <div onClick={onNext} className="button button-next" />
          </div>
        </div>
        {/* NOTE: for prerender slides */}
        <div style={{display: 'none'}}>
          {slides.map((slide) => {
            const parsedSlide = slide?.descriptionHyperLinks ?? parseDescription(slide.description, slide.descriptionHyperLinks)
            return <p key={slide.description}>{parsedSlide}</p>
          })}
        </div>
        <div className="image-container">
          <svg
            className="desktop"
            width="47.29vw"
            height="36.73vw"
            viewBox="0 0 681 529"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M413.208 528.5H398.5L454.792 405.5H469.5L413.208 528.5Z" fill="#E63C24" />
            <mask id="mask-slider" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="681" height="516">
              <path d="M631 452.5L0 516V0L681 82L631 452.5Z" fill="black" />
            </mask>
            <g mask="url(#mask-slider)">
              <image width="681" height="516" href={slides[indexSlide].image} />
            </g>
            <path d="M461.208 496.5H446.5L502.792 373.5H517.5L461.208 496.5Z" fill="#E63C24" />
          </svg>

          <svg
            className="mobile"
            width="100vw"
            height="81.06vw"
            viewBox="0 0 375 304"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M29.2148 304H23L46.7852 249H53L29.2148 304Z" fill="#E63C24" />
            <mask
              id="mask-slider-mobile"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="375"
              height="280"
            >
              <path d="M375 248.889L0 280V0L375 35V248.889Z" fill="#67DBCA" />
            </mask>
            <g mask="url(#mask-slider-mobile)">
              <image width="375" height="280" href={slides[indexSlide].image} />
            </g>
            <path d="M49.6291 297H43L68.3709 242H75L49.6291 297Z" fill="#E63C24" />
          </svg>
        </div>
      </SlideStyled>
    </SliderWithTextStyled>
  )
}

export default SliderWithText
