import BackgroundImage from 'components/common/BackgroundImage'
import Button from 'components/common/Button'
import Cover from 'components/common/Cover'
import Layout from 'components/common/Layout'
import CoverHeadliner from 'components/indexPage/CoverHeadliner'
import SliderWithText from 'components/indexPage/SliderWithText'
import ExpertList from 'components/common/ExpertList'

import Link from 'next/link'
import { NextSeo } from 'next-seo'
import * as SC from 'styles/indexStyles'
import useWindowSize from 'hooks/useWindowSize'
import { useState } from 'react'
import threshold from 'constants/threshold'
import useGetFestivalExperts from 'hooks/useGetFestivalExperts'
import { LIMIT_DESKTOP, LIMIT_PHONE } from 'constants/limits'
import useGetIndustryRepresentatives from 'hooks/useGetIndustryRepresentatives'

const IndexPage = () => {
  const [expertsLimit, setExpertsLimit] = useState<number>(LIMIT_DESKTOP)
  const [expertsOffset, setExpertsOffset] = useState<number>(0)

  const festivalExperts = useGetFestivalExperts({ limit: expertsLimit, offset: expertsOffset })

  const windowSize = useWindowSize()

  if (windowSize.width > parseInt(threshold.phone) && expertsLimit === LIMIT_PHONE) {
    setExpertsLimit(LIMIT_DESKTOP)
  }

  if (windowSize.width < parseInt(threshold.phone) && expertsLimit === LIMIT_DESKTOP) {
    setExpertsLimit(LIMIT_PHONE)
  }

  return (
    <Layout>
      <>
        <SC.IndexPageStyled>
          <Cover
            src="images/homePage/mainCover.jpg"
            srcMobile="images/homePage/mainCoverMobile.jpg"
            srcSize={{ width: '1598', height: '780' }}
            srcMobileSize={{ height: '335', width: '965' }}
            pageData={{ type: 'mainPage' }}
          />

          <SC.EntryDescriptionWrapper>
            <SC.EntryDescriptionStyled>
              <SC.LogoLiteStyled />
              <SC.Description>
                Конференция, профессиональная ярмарка и шоукейс-фестиваль актуальной музыки. К участию в проекте
                приглашаются музыканты в возрасте от 18 до 35 лет, проживающие в России.
              </SC.Description>

              <SC.IntervalWrapper>
                <SC.IntervalLeft>
                  <SC.IntervalDate>15.09-15.11</SC.IntervalDate>
                  <SC.IntervalName>Прием заявок</SC.IntervalName>
                </SC.IntervalLeft>
                <SC.IntervalVector></SC.IntervalVector>
                <SC.IntervalRight>
                  <SC.IntervalDate>09.02-11.02</SC.IntervalDate>
                  <SC.IntervalName>Шоукейс фестиваль</SC.IntervalName>
                </SC.IntervalRight>
              </SC.IntervalWrapper>

              <Button green href="/program">Программа фестиваля</Button>

            </SC.EntryDescriptionStyled>
            <SC.Partner>
              <SC.PartnerSupport>При поддержке</SC.PartnerSupport>
              <SC.PartnerBlock>
                <SC.VkMusicLogoStyled />
                <SC.TramplinLogoStyled />
              </SC.PartnerBlock>
            </SC.Partner>
          </SC.EntryDescriptionWrapper>

          <div className="concert-container">
            <BackgroundImage
              position="center"
              src="images/homePage/image-center1.png"
              srcMobile="images/homePage/image-center-mobile1.png"
            />
          </div>

          <SliderWithText />

          {/* {festivalExperts.data?.count !== 0 && (
            <ExpertList
              title="Эксперты 2023"
              data={festivalExperts.data}
              isLoading={festivalExperts.isLoading}
              isError={festivalExperts.isError}
              isPaginate
              limit={expertsLimit}
              setOffset={setExpertsOffset}
              offset={expertsOffset}
            />
          )} */}

          <BackgroundImage
            position="bottom"
            src="images/homePage/image-bottom1.png"
            srcMobile="images/homePage/image-bottom-mobile1.png"
          />
        </SC.IndexPageStyled>
      </>
    </Layout>
  )
}

export default IndexPage
